import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "./App.css";
import compass from "./img/compass.svg";
import euro from "./img/euro.svg";
import grid1 from "./img/grid1.png";
import grid2 from "./img/grid2.png";
import grid3 from "./img/grid3.png";
import grid4 from "./img/grid4.png";
import heavyStars from "./img/heavy_stars.svg";
import logo from "./img/logo.png";
import lumen from "./img/lumen.svg";
import phone1 from "./img/phone1.jpg";
import phone2 from "./img/phone2.jpg";
import phone3 from "./img/phone3.jpg";
import pound from "./img/pound.svg";
import sparseStars from "./img/sparse_stars.svg";
import usd from "./img/usd.svg";
import yuan from "./img/yuan.svg";

function App() {
  const [option, setOption] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calculateOpacity = () => {
    const maxScroll = 700;
    const opacity = 1 - Math.min(1, scrollPosition / maxScroll);
    return opacity;
  };

  return (
    <div className="App">
      <nav className="navbar">
        {/* eslint-disable-next-line */}
        <a href="#">
          <p>HOME</p>
        </a>
        <a href="#tokenmics">
          <p>TOKENMICS</p>
        </a>
        <a href="#roadmap">
          <p>ROADMAP</p>
        </a>
        <a href="#metaverse">
          <p>METAVERSE</p>
        </a>
        <a href="#partners">
          <p>PARTNERS</p>
        </a>
        <button>Get Started</button>
      </nav>
      <motion.div
        className="iconContainer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <img src={logo} alt="logo" />
      </motion.div>
      <main>
        {/* compass */}
        <div className="compassContainer">
          <img src={compass} alt="compass" />
        </div>
        {/* title */}
        <motion.div
          className="titleContainer"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          <h1>AKB is an open network for</h1>
          <h1>Antimatter kingdom metaverse</h1>
        </motion.div>
        {/* currencies */}
        <div
          className="currencyContainer"
          style={{ opacity: calculateOpacity() }}
        >
          <div className="euroContainer">
            <img src={euro} alt="euro" />
          </div>
          <div className="usdContainer">
            <img src={usd} alt="usd" />
          </div>
          <div className="yuanContainer">
            <img src={yuan} alt="yuan" />
          </div>
          <div className="lumenContainer">
            <img src={lumen} alt="lumen" />
          </div>
          <div className="poundContainer">
            <img src={pound} alt="pound" />
          </div>
        </div>
        {/* stars */}
        <div>
          <div className="heavyStarsContainer">
            <img src={heavyStars} alt="heavy stars" />
          </div>
          <div className="sparseStarsContainer">
            <img src={sparseStars} alt="sparse stars" />
          </div>
        </div>
        {/* subtitle */}
        <motion.div
          id="tokenmics"
          initial={{ opacity: 0, y: 80 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
          className="subTitleContainer"
        >
          <h1>Borderless · Deflationary · Powerful</h1>
          <p>
            AKB is an experiment in decentralized spontaneous community
            building. AKB coin is
          </p>
          <p>scarce and full of potential. of AKB's contract address is:</p>
          <p>
            <b>0x7cE42C6D2305B89e3916e61316826d18cdcAAC51</b> AKB is a coin on
            Binance Smart Chain
          </p>
          <p>boasting a number of impressive features.</p>
          <button>Learn More</button>
        </motion.div>
        {/* phones */}
        <div id="roadmap">
          <motion.div
            initial={{ opacity: 0, y: 80 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.75 }}
            className="sectionTitle"
          >
            <h1>AKB makes money and metaverse better</h1>
            <p>
              AKB's API and SDKs are ready to help you transform the world of
              finance, and the
            </p>
            <p>
              network's currency connections could give even a people the power
              and reach of anywhere
            </p>
            <p>
              metaverse. Here's what you can a build with a few lines of code:
            </p>
          </motion.div>
          <motion.section
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.75 }}
            className="section"
          >
            <div className="phoneContainer">
              <img src={phone1} alt="phone1" />
            </div>
            <div className="text">
              <h2>The AKB total number is 10,000,000</h2>
              <p>50% destruction</p>
              <p>20% create flow pool</p>
              <p>20% white list distribution</p>
              <p>5% community promotion and operation</p>
              <p>5% airdrop and destruction</p>
            </div>
          </motion.section>
          <motion.section
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.75 }}
            className="section"
          >
            <div className="phoneContainer">
              <img src={phone2} alt="phone2" />
            </div>
            <div className="text">
              <h2>Asset exchanges</h2>
              <p>Use AKB's built-in decentralized exchange for crypto</p>
              <p>and metaverse. Users can swap between coins using</p>
              <p>simple functions built into the protocol.</p>
            </div>
          </motion.section>
          <motion.section
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.75 }}
            className="section"
          >
            <div className="phoneContainer">
              <img src={phone3} alt="phone3" />
            </div>
            <div className="text">
              <h2>AKB Rewards in your wallet</h2>
              <p>AKB is a deflationary token designed to become more</p>
              <p>scarce over time. All holders of AKB will earn more AKB</p>
              <p>that is automatically sent to your wallet by simply</p>
              <p>holding AKB coins in your wallet. Watch the amount of</p>
              <p>AKB grow in your wallet as all holders automatically</p>
              <p>receive a 10% fee from every transaction that happens</p>
              <p>on the AKB network. The community receives more AKB</p>
              <p>coins from the fees generated each transaction.</p>
            </div>
          </motion.section>
        </div>
        {/* codes */}
        <motion.div
          id="metaverse"
          className="frameContainer"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
        >
          <div className="devTitle">
            <h1>Designed for developers</h1>
            <p>
              AKB has the documentation, tooling, and support to help you get
              your project up quickly.
            </p>
            <button>Explore resources</button>
          </div>
          <div className="btnAndFrames">
            <div className="options">
              <button
                onClick={() => setOption(1)}
                style={{
                  backgroundColor:
                    option === 1 ? "rgba(128,0,128,0.35)" : "transparent",
                }}
              >
                Create AKB account
              </button>
              <button
                onClick={() => setOption(2)}
                style={{
                  backgroundColor:
                    option === 2 ? "rgba(128,0,128,0.35)" : "transparent",
                }}
              >
                Send AKB payment
              </button>
              <button
                onClick={() => setOption(3)}
                style={{
                  backgroundColor:
                    option === 3 ? "rgba(128,0,128,0.35)" : "transparent",
                }}
              >
                Antimatter kingdom
              </button>
              <button
                onClick={() => setOption(4)}
                style={{
                  backgroundColor:
                    option === 4 ? "rgba(128,0,128,0.35)" : "transparent",
                }}
              >
                Make a AKB buy offer
              </button>
            </div>
            <div className="frames" style={{ paddingLeft: "10vw" }}>
              {option === 1 && (
                <iframe
                  title="code1"
                  src={process.env.PUBLIC_URL + `iframe_code1.html`}
                  className="frame"
                />
              )}
              {option === 2 && (
                <iframe
                  title="code2"
                  src={process.env.PUBLIC_URL + `iframe_code2.html`}
                  className="frame"
                />
              )}
              {option === 3 && (
                <iframe
                  title="code3"
                  src={process.env.PUBLIC_URL + `iframe_code3.html`}
                  className="frame"
                />
              )}
              {option === 4 && (
                <iframe
                  title="code4"
                  src={process.env.PUBLIC_URL + `iframe_code4.html`}
                  className="frame"
                />
              )}
            </div>
          </div>
        </motion.div>
        {/* sponsor */}
        <motion.div
          id="partners"
          className="sponsorContainer"
          initial={{ opacity: 0, y: 80 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
        >
          <div className="title">
            <h1>Built on AKBcoin</h1>
            <p>
              Large enterprise companies and companies as small as single-dev
              startups have
            </p>
            <p>
              chosen AKBcoin to move money and metaverse access new markets.
            </p>
          </div>
          <button>See all projects</button>
          <div className="logoContainer">
            <a href="https://www.coingecko.com/">
              <img src={grid1} alt="CoinMarketCap" />
            </a>
            <a href="https://www.coingecko.com/">
              <img src={grid2} alt="CoinGecko" />
            </a>
            <a href="https://bscscan.com/address/0x7ce42c6d2305b89e3916e61316826d18cdcaac51">
              <img src={grid3} alt="BscScan" />
            </a>
            <a href="https://pancakeswap.finance/swap?outputCurrency=0x7cE42C6D2305B89e3916e61316826d18cdcAAC51">
              <img src={grid4} alt="PancakeSwap" />
            </a>
          </div>
        </motion.div>
      </main>
      <footer>
        <div className="links">
          <motion.div
            className="iconContainer"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <img src={logo} alt="logo" />
          </motion.div>
          <div className="crypto">
            <p>CRYPTO</p>
            <a href="https://www.coingecko.com/">CoinGecko</a>
            <a href="https://www.coingecko.com/">Live Coin Watch</a>
            <a href="https://coinmarketcap.com/">CoinMarketCap</a>
          </div>
          <div className="exchange">
            <p>EXCHANGE</p>
            <a href="https://pancakeswap.finance/swap?outputCurrency=0x7cE42C6D2305B89e3916e61316826d18cdcAAC51">
              PancakeSwap
            </a>
          </div>
          <div className="social">
            <p>SOCIAL</p>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <TwitterIcon
                style={{ color: "rgba(255,255,255,0.35)", marginRight: "5px" }}
              />
              <a href="https://twitter.com/AKB_Coin">Twitter</a>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <TelegramIcon
                style={{ color: "rgba(255,255,255,0.35)", marginRight: "5px" }}
              />
              <a href="https://t.me/AKBCoinen">Telegram</a>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
            Copyright © 2021 KB. All Rights Reserved AKB is not an investment
            and created as a metaverse. AKB makes no promises and is not
            responsible for any losses or errors use at your own risk. None of
            the information on this website should be construed as providing
            legal or financial advice. Please use at your own risk. AKB coin is
            not a registered broker, analyst or investment advisor. If you are
            willing to, or have purchased AKB, you agree that you are not
            purchasing a security or investment. The AKB team can be not be held
            liable for any losses or taxes you may incur.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
